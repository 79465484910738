<template>
  <div>
    <apexchart type="area" width="100%" height="300px" :options="options" :series="series"></apexchart>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: {
    data: {
      type: Array,
      required: false,
    },
  },
  watch: {
    data: {
      handler(newVal) {
        if (newVal) {
          this.GetData();
        }
      },
      deep: true, // Add this line to watch for nested changes in the filter object
    },
  },
  data() {
    return {
      VisitsData: [],
      ChartData: [],
      ChartLabels: [],
      Data: [],
      series: [
        {
          name: "Visits",
          data: [],
        },
      ],
      Filter: {
        "days": 30,
        "fromDate": new Date(),
        "toDate": new Date(),
        "userId": localStorage.getItem("userId"),
      },
      options: {
        grid: {
          show: true, // Set to true to show the grid
          borderColor: '#E0E0E0', // Change the grid border color here
          strokeDashArray: 2, // Set to 0 for solid lines, or a number for dashed lines
          xaxis: {
            lines: {
              show: true, // Set to true to show the x-axis grid lines
            },
          },
          yaxis: {
            lines: {
              show: true, // Set to true to show the y-axis grid lines
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return Math.round(val); // Round the value to the nearest integer
            },
          },
        },
        stroke: {
          show: true,
          // curve: 'straight', // You can change the curve type here
          width: 1, // Change the stroke width here
          colors: ["#3E8C3C", "#238a20", "#17600a", "#1976D2", "#C0CA33"],
        },
        dataLabels: {
          enabled: false
        },
        chart: {
          type: 'area',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              return Math.round(val); // Round the value to the nearest integer
            },
          },
          fill: {
            opacity: 1,
          },
        },
        fill: {
          colors: ["#3E8C3C", "#36b633", "#074d05", "#3E8C3C", "#3E8C3C"],
          opacity: 5
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              size: "25px",
            }

          }
        }
      }
    }
  },
  methods: {
    async GetData() {
      try {
        if (this.data === undefined) return;
        this.data.forEach(element => {
          this.ChartLabels.push(moment(element.date).format("DD MMM yyyy"))
          this.ChartData.push(element.count)
        });
        this.updateSeries();

        //this.updateCategories(this.CreateCategories(this.$store.state.Filter.days));
        this.updateCategories(this.ChartLabels);
      } catch (e) {
        console.log(e);
      }
    },
    CreateCategories(days) {
      const dates = [];
      for (let i = 0; i < days; i++) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        dates.push(moment(date).format("DD MMM YYYY"));
      }

      return dates;
    },
    updateCategories(newCategories) {
      this.options = {
        ...this.options,
        xaxis: {
          ...this.options.xaxis,
          categories: newCategories,
        },
      };
    },
    updateSeries() {
      this.series = [
        {
          name: "Visits",
          data: this.ChartData,
        },
      ];
    },

  },
  created() {
    this.GetData();
  }
}
</script>