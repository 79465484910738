import { createStore } from 'vuex'

export default createStore({
  state: {
    LoggedIn: false,
    sidebarCollapsed: false,
    isDomain: true,
    isLocked: false,
    pageId: 0,
    Domain: {
      "userId": sessionStorage.getItem("userId"),
      "id": 0,
      "domainName": "",
      "subDomain": "",
      "status": 20,
      "type": 5,
      "landingUrl": "",
      "errorUrl": "",
    },
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
