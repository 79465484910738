<template>
  <div class="common-layout m-0 p-0">
    <el-container style="height: 100vh; overflow-y: auto !important;">
      <el-header class="m-0 p-0" v-if="$store.state.LoggedIn">
        <HeaderComp/>
      </el-header>
      <el-container>
        <el-aside v-if="$store.state.LoggedIn" :style="{ width: $store.state.sidebarCollapsed ? '0' : '140px' }"
                  class="cstm-sidebar">
          <SidebarComp/>
        </el-aside>
        <el-main class="m-0 p-0 main-r">
          <router-view class="p-2 my-router"/>
          <div v-if="isLocked" class="overlay">
            <span class="overlay-text">Please activate your subscription code from top right corner</span>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>

</template>
<script>
import HeaderComp from "@/components/Navigation/HeaderComp.vue";
import SidebarComp from "@/components/Navigation/Sidebar.vue";

export default {
  components: {HeaderComp, SidebarComp},
  computed: {
    isLocked() {
      // Replace this with your logic to determine the locked status of the user
      return this.$store.state.isLocked;
    }
  },
}
</script>
<style>
@import "assets/Styles/Style.css";
@import "./assets/MingIcons/MingCute.css";
@import "./assets/RemixIcons/remixicon.css";
@import "assets/bootstrap/css/bootstrap.css";

.el-aside {
  transition: width 0.3s ease !important;
}

.my-router {
  overflow-x: hidden !important;
}

.main-r {
  position: relative !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 3 !important;
}

.overlay-text {
  position: absolute;
  width: auto;
  top: 300px;
  color: #fff;
  font-weight: bold;
  font-family: Poppins, serif;
  font-size: 25px;
  left: calc(50% - 350px);
}


</style>
