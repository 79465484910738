<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Add Magic Url</h3>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end">
        <router-link to="magicurls">
          <el-button text bg class="me-2">
            <i class="bi bi-arrow-left-short me-2"></i>
            Cancel
          </el-button>
        </router-link>
        <el-button v-if="MagicUrl.linkId < 1" type="primary" @click="generateByteArray()"
          :disabled="!MagicUrl.asin || !MagicUrl.name">Add Magic Url</el-button>
        <el-button v-else type="primary" @click="generateByteArray()">Update Magic Url</el-button>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <div class="col-12 col-md-6">
      <el-form label-position="top" v-model="MagicUrl">
        <el-form-item>
          <template #label>
            <span class="required">Campaign Name</span>
          </template>
          <el-input v-model="MagicUrl.title"></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="">Domain / Slug</span>
          </template>
          <el-input :disabled="MagicUrl.linkId > 0" v-model="MagicUrl.linkGuid">
            <template #prepend>
              <el-select :disabled="MagicUrl.linkId > 0" style="width: 250px" v-model="MagicUrl.customUrl">
                <el-option v-for="domain in Domains" :key="domain.id" :label="domain.name"
                  :value="domain.name"></el-option>
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="required">ASIN</span>
          </template>
          <el-input v-model="MagicUrl.asin" @keydown.space.prevent>
          </el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="">Marketplace</span>
          </template>
          <el-select style="width: 250px" v-model="MagicUrl.marketPlace" class="w-100">
            <el-option v-for="country in Countries" :key="country.value" :label="country.country"
              :value="country.country"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="">Select Pixel</span>
          </template>
          <el-select multiple v-model="LocalPixelCodes" placeholder="select pixel" @change="CheckPixel()">
            <el-option v-for="code in PixelCodes" :key="code.id" :label="code.codeName" :value="code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div class="d-flex justify-content-between">
              <span class="">Keywords</span>
              <el-button type="primary" circle @click="addKeywordDialog = !addKeywordDialog">+</el-button>
            </div>
          </template>
          <el-table :data="MagicUrl.weights" :fit="true" max-height="80vh" class="cstm-table"
            header-cell-class-name="tbl-header" size="small" stripe>
            <el-table-column label="Keyword" prop="keyword"></el-table-column>
            <el-table-column label="Weight" prop="weight"></el-table-column>
            <el-table-column width="55">
              <template #default="scope">
                <i class="mgc_delete_2_line text-danger action-icon" @click="removeKeyword(scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>


    <el-dialog v-model="addKeywordDialog" width="400" title="Add Keyword" draggable>
      <el-form label-position="top">
        <el-form-item label="Keyword">
          <el-input v-model="editedKeyword.keyword" placeholder="e.g Gaming Laptop"></el-input>
        </el-form-item>
        <el-form-item label="Weight">
          <template #label>
            <span>Weight </span><span class="text-caption ">(e.g 10)</span>
          </template>
          <el-input v-model="editedKeyword.weight" type="number" min="0" step="1" placeholder="e.g 10"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg @click="addKeywordDialog = false">Cancel</el-button>
          <el-button :disabled="!editedKeyword.keyword || editedKeyword.weight < 1" type="primary"
            @click="addKeyword()">Add
          </el-button>
        </div>
      </template>
    </el-dialog>
    <div class="d-none">
      <div ref="qrcodeContainer">
        <QRCodeVue3 ref="qrcode" :width="150" :height="150" :value="MagicUrl.customUrl + MagicUrl.linkGuid"
          :key="updateQrcode" :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }" :dotsOptions="{
          type: 'dots',
          color: '#26249a',
          gradient: {
            type: 'linear',
            rotation: 0,
            colorStops: [
              { offset: 0, color: '#000' },
              { offset: 1, color: '#000' },
            ],
          },
        }" v-bind:image="MagicUrl.logo" :backgroundOptions="{ color: '#ffffff' }"
          :cornersSquareOptions="{ type: 'extra-rounded', color: '#000000' }"
          :cornersDotOptions="{ type: 'sqaure', color: '#000000' }" fileExt="png" myclass="my-qur" imgclass="img-qr"
          downloadButton="btn btn-primary" :downloadOptions="{ name: 'vqr', extension: 'webp' }" />
      </div>
    </div>

    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import Config from "@/Configuration/Config";
import domainService from "@/Services/domainService";
import pixelCodeService from "@/Services/pixelCodeService";
import qrCodeService from "@/Services/linkService";
import store from "@/store";
import linkService from "@/Services/linkService";
import { ShowMessage } from "@/Utility/Utility";
import QRCodeVue3 from "qrcode-vue3";
export default {
  name: "AddMagicUrl",
  components: { QRCodeVue3 },
  data() {
    return {
      loading: false,
      addKeywordDialog: false,
      editedKeyword: {
        keyword: "",
        weight: 0,
      },
      updateQrcode: new Date(),
      LocalPixelCodes: [],
      PixelCodes: [],
      Domains: [],
      Countries: [
        { country: "United States", value: "com" },
        { country: "Canada", value: "ca" },
        { country: "Mexico", value: "com.mx" },
        { country: "United Kingdom", value: "co.uk" },
        { country: "Germany", value: "de" },
        { country: "Spain", value: "es" },
        { country: "France", value: "fs" },
        { country: "India", value: "co.in" },
        { country: "Italy", value: "it" },
        { country: "Japan", value: "co.jp" },
      ],
      MagicUrl: {
        linkId: 0,
        title: "",
        asin: "",
        marketPlace: "United States",
        linkGuid: "",
        customUrl: Config.apiBaseUrl,
        weights: [],
        userId: sessionStorage.getItem("userId"),
        linkType: 20,
        pixelCodes: [],
        pixelCode: "",
        //domainName: "https://mglz.us",
        //name: "https://mglz.us",
        // domainName: "https://mglz-dev.azurewebsites.net",
        // name: "https://mglz-dev.azurewebsites.net",
        domainName: Config.apiBaseUrl,
        name: Config.apiBaseUrl,
      },
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isVerified: true,
        userId: sessionStorage.getItem("userId"),
        isActive: true,
      },
    }
  },
  methods: {
    async generateByteArray() {
      try {
        let response = await qrCodeService.checkSlug(this.MagicUrl.linkGuid, this.MagicUrl.linkId);
        this.MagicUrl.linkGuid = response.data;
        this.updateQrcode = new Date();

        // Wait for the QR code generation to complete
        await this.$nextTick();

        // Fetch the image source after waiting for the asynchronous operations
        await new Promise(resolve => setTimeout(resolve, 0)); // This micro-task ensures the next tick
        const imgSrc = document.querySelector('.img-qr').getAttribute('src');

        this.MagicUrl.qrCode = imgSrc;
        // Now call the CreateURL method
        this.CreateCampaign();
      } catch (error) {
        console.error('Error generating Base64 string:', error);
      }
    },
    addKeyword() {
      this.MagicUrl.weights.push(this.editedKeyword);
      this.editedKeyword = {
        keyword: "",
        weight: 0
      };
      this.addKeywordDialog = false;
    },
    removeKeyword(row) {
      const index = this.MagicUrl.weights.indexOf(row);
      if (index !== -1) {
        this.MagicUrl.weights.splice(index, 1);
      }
    },
    CheckPixel() {
      this.MagicUrl.pixelCodes = [];
      this.LocalPixelCodes.forEach(element => {
        let object = {
          pixelId: element.id
        }
        this.MagicUrl.pixelCodes.push(object)
      });
    },
    async GetDomains() {
      try {
        this.loader = true;
        let response = await domainService.all(this.filter)
        this.Domains = response.data.data;
        this.loader = false;
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
    async GetPixels() {
      try {
        this.loader = true;
        let response = await pixelCodeService.getAll(this.filter)
        this.PixelCodes = response.data.data;
        this.loader = false;
      } catch (e) {
        console.log(e);
      }
    },
    async CreateCampaign() {
      try {
        this.loading = true;
        if (this.MagicUrl.customUrl === null || this.MagicUrl.customUrl === "") {
          this.MagicUrl.customUrl = Config.apiBaseUrl;
        }
        // const lastChar = this.MagicUrl.customUrl.slice(-1);
        // if(lastChar === '/'){
        //   this.MagicUrl.customUrl = this.MagicUrl.customUrl.replace(/\/$/, "");
        // }
        if (!this.MagicUrl.customUrl.startsWith("https://") && !this.MagicUrl.customUrl.startsWith("http://")) {
          // If not, add "https://" to the beginning of the string
          this.MagicUrl.customUrl = "https://" + this.MagicUrl.customUrl;
        }
        if (this.MagicUrl == null) return;
        let response = await linkService.addOrUpdate(this.MagicUrl);
        this.loading = false;

        if (response.data.isSuccessful === true) {
          if (this.MagicUrl.linkId > 0) {
            ShowMessage("success", "Link updated successfully!");
          }
          else {
            ShowMessage("success", "Link created successfully!");
          }
          this.clearForm();
        }
      } catch (error) {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    },
    clearForm() {
      this.MagicUrl = {
        title: "",
        asin: "",
        marketPlace: "",
        linkGuid: "",
        weights: [],
        userId: localStorage.getItem("userId"),
        linkType: 20,
        pixelCode: "",
        domainName: Config.apiBaseUrl,
        customUrl: "",
      };
      this.LocalPixelCodes = [];
    },
    async GetLink() {
      try {
        this.loading = true;
        let res = await qrCodeService.getLink(store.state.pageId);
        this.MagicUrl = res.data.data;
        if (this.MagicUrl.pixelCodes != null && this.MagicUrl.pixelCodes.length > 0) {
          this.MagicUrl.pixelCodes.forEach(element => {
            this.LocalPixelCodes.push(element)
          })
        }
        // const lastChar = this.MagicUrl.customUrl.slice(-1);
        // if (this.MagicUrl.customUrl.charAt(this.MagicUrl.customUrl.length - 1) !== '/') {
        //   // Add a slash to the end
        //   this.MagicUrl.customUrl += '/';
        // }
        this.updateQrcode = new Date();
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    }
  },
  created() {
    if (store.state.pageId > 0) {
      this.GetLink();
    }
    this.GetPixels();
    this.GetDomains();
  }
}
</script>
<style scoped></style>