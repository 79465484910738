<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Domain Configuration</h3>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end">
        <router-link to="add-domain">
          <el-button text bg class="me-2">
            <i class="bi bi-arrow-left-short me-2"></i>
            Cancel
          </el-button>
        </router-link>
        <el-button v-if="Domain.id < 1" type="primary" @click="AddDomain()">Add Domain</el-button>
        <el-button v-else type="primary" @click="AddDomain()">Update Domain</el-button>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <div class="col-12 col-md-8 col-xl-6 bg-body-secondary p-3 rounded-1">
      <div>
        <span class="fw-bold text-caption">How to connect your custom domain</span><br>
        <span class="text-caption">To connect your domain, you will need to update your domain's DNS settings. This is managed in your domain provider, not in Magiclinkz.com. Follow the step-by-step instructions below to get started.</span>
      </div>
      <div>
        <span class="fw-bold text-caption">Instructions</span><br>
        <el-divider class="my-1"></el-divider>
        <span class="text-caption"><span
            class="fw-bold">1</span>. In a new window, log in to your domain provider.</span>
        <el-divider class="my-1"></el-divider>
        <span class="text-caption"><span class="fw-bold">2</span>. Locate the DNS settings. This might be under DNS Manager, Manage Domains, Domain Manager, or something similar.</span>
        <el-divider class="my-1"></el-divider>
        <span class="text-caption"><span class="fw-bold">3</span>. Once you locate the DNS settings, you'll want to look for A records. If A record already exist, you can update them to match the information below. If they don't, you'll need to create A record to match these.</span>
      </div>
      <div>
        <table class="table text-caption" v-if="!this.$store.state.isDomain">
          <thead>
          <tr>
            <td class="font-weight-bold">Type</td>
            <td class="font-weight-bold">Host</td>
            <td class="font-weight-bold">Value</td>
            <td class="font-weight-bold"></td>
          </tr>
          </thead>
          <tbody>
          <tr class="grey lighten-3 rounded">
            <td>CNAME</td>
            <td>{{ Domain.subDomain }}</td>
            <td id="subapp">magiclinkz.azurewebsites.net</td>
            <td class="d-flex">
              <el-button size="small" text bg class="ms-auto copy-text d-flex align-center"
                         @click="CopyText('subapp')">
                <i class="ri-file-copy-2-line" style="font-size: 18px"></i></el-button>
            </td>
          </tr>
          <tr class="grey lighten-3">
            <td>TXT</td>
            <td>asuid.{{ Domain.subDomain }}</td>
            <td id="asuid" style="max-width: 150px;" class="text-truncate">
              BCECF58320C86A598B61D46AB5FB9D3ED7714B05D6467DD20B76058ACC08F967
            </td>
            <td class="d-flex">
              <el-button size="small" text bg class="ms-auto copy-text d-flex align-center"
                         @click="CopyText('asuid')">
                <i class="ri-file-copy-2-line" style="font-size: 18px"></i>
              </el-button>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table mt-3 w-100 text-caption" v-if="this.$store.state.isDomain">
          <tbody>
          <tr
              class="grey lighten-3 rounded"
          >
            <td class="font-weight-bold">Type</td>
            <td class="font-weight-bold">Host</td>
            <td class="font-weight-bold">Value</td>
            <td class="font-weight-bold"></td>
          </tr>
          <tr class="grey lighten-3 rounded">
            <td>A</td>
            <td>@</td>
            <td id="@">20.192.98.160</td>
            <td class="d-flex">
              <el-tooltip content="Copy">
                <el-button size="small" text bg class="ms-auto copy-text d-flex align-center" @click="CopyText('@')">
                  <i class="ri-file-copy-2-line" style="font-size: 18px"></i></el-button>
              </el-tooltip>
            </td>
          </tr>
          <tr class="grey lighten-3">
            <td>TXT</td>
            <td>asuid</td>
            <td id="asuid" style="max-width: 150px;" class="text-truncate">
              BCECF58320C86A598B61D46AB5FB9D3ED7714B05D6467DD20B76058ACC08F967
            </td>
            <td class="d-flex">
              <el-tooltip content="Copy">
                <el-button size="small" text bg class="ms-auto copy-text d-flex align-center"
                           @click="CopyText('asuid')">
                  <i class="ri-file-copy-2-line" style="font-size: 18px"></i></el-button>
              </el-tooltip>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <span class="text-caption"><span
            class="fw-bold">5</span>. If there are more than two CNAME records, remove all others and leave only the two that you configured above.</span>
        <el-divider class="my-1"></el-divider>
        <span class="text-caption"><span
            class="fw-bold">6</span>.  When you are done, click Add subdomain button.</span>
        <el-divider class="my-1"></el-divider>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <router-link to="add-domain">
          <el-button text bg class="me-2">Cancel</el-button>
        </router-link>
        <el-button type="primary" @click="CheckDomain()">Add Domain</el-button>
      </div>
    </div>
    <div v-if="loading" class="spinner-border login-loader"></div>
  </div>
</template>
<script>
import store from "@/store";
import {ShowMessage} from "@/Utility/Utility";
import domainService from "@/Services/domainService";

export default {
  name: "DomainInstructions",
  data() {
    return {
      loading: false,
      copyText: "Copy",
      Domain: store.state.Domain,
    }
  },
  methods: {
    CopyText(type) {
      let text = document.getElementById(type).innerHTML;
      text = text.trim();
      navigator.clipboard.writeText(text);
      ShowMessage("success", "Copied");
    },
    async CheckDomain() {
      try {

        this.loading = true;
        let domain = "";
        if (!this.$store.state.isDomain) {
          domain = this.Domain.subDomain + "." + this.Domain.domainName;
        } else {
          domain = this.Domain.domainName;
        }
        let response = await domainService.add(domain);
        console.log(response)
        if (response.data.isSuccessful === true) {
          this.loader = false;
          this.Domain.status = true;
          this.AddOrUpdate();
          ShowMessage("success", response.data.msg)
        } else {
          this.loader = false;
          ShowMessage("error", response.data.msg)
          this.Domain.status = false;
          this.AddOrUpdate();
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
        ShowMessage("error", "Adding domain failed.");
      }
    },
    async AddOrUpdate() {
      try {
        this.loading = true;
        switch (this.$store.state.isDomain) {
          case true:
            this.Domain.type = 5;
            break;
          case false:
            this.Domain.type = 10;
            break;
        }
        let response = await domainService.addOrUpdate(this.Domain);

        this.loading = false;
        if (response.data.isSuccessful === false) {
          ShowMessage("error", response.data.msg)
        }
        if (response.data.isSuccessful === true) {
          this.$router.push('/domains')
        }

      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
  }
}
</script>
<style scoped>

</style>