<template>
  <div class="pa-1 custom-container">
<!--    <v-img v-if="options.series.length === 0" src="../../../../assets/Images/no-data-svg.svg" width="400"></v-img>-->
    <apexchart  type="donut" :options="options" height="280px" :series="options.series"></apexchart>
  </div>
</template>
<script>

export default {
  name: 'BrowserChart',
  data: function () {
    return {
      TopPlatforms: [],
      Data: [],
      Filter: {
        "days": 30,
        "fromDate": new Date(),
        "toDate": new Date(),
        "userId": localStorage.getItem("userId"),
      },
      options: {
        series: [],
        labels: [],
        dataLabels: {
          enabled: false,
        },
        tooltip :{
          theme: 'dark', // Change the tooltip theme to 'dark',
          fillSeriesColor: false,
          marker: {
            fillColors: ["#FFFFFF","#EC407A","#D500F9","#1976D2","#C0CA33"],
          }
        },
        legend: {
          show: false
        },
        fill: {
          colors: ["#3E8C3C", "#36b633", "#074d05", "#539352", "#3E8C3C"],
          opacity: 1
        },
        plotOptions: {
          pie: {
            donut: {
              size: '30%',
              labels: {
                show: false,
              }
            }
          }
        }
      },
      MyData: []
    }
  },
  props: {
    data: {
      type: Array,
      required: false,
    },
  },
  watch: {
    data: {
      handler(newVal) {
        if (newVal) {
          this.GetData();
        }
      },
      deep: true, // Add this line to watch for nested changes in the filter object
    },
  },
  methods: {
    async GetData() {
      try {
        if(this.data.length < 1) return;
        this.options.series = [];
        this.options.labels = [];
        this.TopPlatforms = this.data;
        this.TopPlatforms.forEach(element => {
          this.options.series.push(element.count)
          this.options.labels.push(element.title)
        })
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    this.GetData();
  }

};
</script>
<style scoped>
.custom-container img{

}
</style>