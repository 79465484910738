<template>
  <div class="container-fluid login-page">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 col-md-5 col-lg-4 justify-content-center align-items-center">
        <el-card shadow="never">
          <div class="text-center">
            <img src="../../assets/img/logo.png" width="200">
            <h6>Signup</h6>
          </div>
          <el-form :model="User" :rules="formRules" label-position="top" class="mt-4" hide-required-asterisk>
            <el-form-item prop="fullName">
              <template #label>
                <span class="required">FullName</span>
              </template>
              <el-input v-model="User.fullName"></el-input>
            </el-form-item>
            <el-form-item prop="username">
              <template #label>
                <span class="required">Username / Email</span>
              </template>
              <el-input v-model="User.username"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <template #label>
                <span class="required">Password</span>
              </template>
              <el-input v-model="User.password"></el-input>
            </el-form-item>
            <el-form-item prop="retypePassword">
              <template #label>
                <span class="required">Retype Password</span>
              </template>
              <el-input v-model="User.retypePassword"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-8">
                  <el-button :disabled="!User.fullName || !User.username || !User.password" size="large" type="primary"
                    class="w-100" @click="Signup">Signup
                  </el-button>
                </div>
                <div class="col-12 col-md-4 mt-2 mt-md-0">
                  <el-button @click="gotoHome()" size="large" type="primary" class="w-100">Login</el-button>

                </div>

              </div>
            </div>
          </template>
        </el-card>
      </div>
    </div>
    <div v-if="loading" class="spinner-border login-loader"></div>
  </div>
</template>
<script>
import Vue from "@vue/cli-plugin-eslint/eslintOptions";
import userService from "@/Services/userService";
import { ShowMessage } from "@/Utility/Utility";

export default {
  name: "SignUp",
  data() {
    return {
      loading: false,
      User: {
        fullName: "",
        username: "",
        password: "",
        retypePassword: ""
      },
      formRules: {
        password: [
          { required: true, message: 'Please enter your password', trigger: 'blur' },
          // You can add more rules for the name field if needed
        ],
        username: [
          { required: true, message: 'Please enter the email', trigger: 'blur' },
          { type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change'] },
          { validator: this.validateEmail, trigger: 'blur' }
        ],
        retypePassword: [
          { required: true, message: 'Password does not match', trigger: 'blur' },
          /*  {type: 'password', message: 'Password does not match', trigger: ['blur', 'change']},*/
          { validator: this.validatePassword, trigger: ['blur', 'change'] }
        ],
      },
    }
  },
  methods: {
    gotoHome(){
      this.$router.push('/')
    },
    async Signup() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await userService.signup(this.User);
        if (response.data.isSuccessful) {
          this.loading = false;
          ShowMessage("success", response.data.msg);
          setTimeout(this.$router.push('/'), 3000)
        } else {
          this.loading = false;
          ShowMessage("error", "Something went wrong!");
        }
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    },
    validatePassword(rule, value, callback) {
      if (value == this.User.password) {
        callback();
      } else {
        callback(new Error('Password does not match'));
      }
    },
    validateEmail(rule, value, callback) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        callback();
      } else {
        callback(new Error('Please enter a valid email address'));
      }
    },
  },
  created() {
    // Suppress console warnings for this component
    Vue.config.silent = true;
  },

  unmounted() {
    // Revert the silent mode when the component is destroyed
    Vue.config.silent = false;
  },
}
</script>

<style scoped></style>