<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Qr Codes</h3>
      </div>
      <div class="col-12 col-md-4 d-flex">
        <el-input v-model="filter.filterText" @clear="GetAll()" @keyup="GetAll()" placeholder="search here..." clearable class="me-1">
          <template #append>
            <el-button type="primary">
              <i class="bi bi-search"></i>
            </el-button>
          </template>
        </el-input>
        <router-link to="addcode">
          <el-button @click="$store.state.pageId = 0" type="primary">Add Qr Code</el-button>
        </router-link>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <el-table :data="QrCodes" :fit="true" max-height="80vh"
              class="cstm-table" header-cell-class-name="tbl-header" size="small" stripe
    >
      <template #empty>
        <el-empty :image-size="100" class="py-1">
          <template #description>
            <span class="my-0">No Data Found</span>
          </template>
        </el-empty>
      </template>
      <template #append>
        <div class="d-flex justify-content-center py-2 px-1">
          <el-pagination
              v-if="QrCodes.length > 0"
              v-model:current-page="pagination.page"
              v-model:page-size="pagination.pageSize"
              :page-sizes="[25, 50, 100]"
              :total="pagination.fetched"
              background
              layout="prev, pager, next, ,sizes"
              small
          />
        </div>
      </template>
      <el-table-column label="Title" prop="title">
        <template #default="scope">
          <div class="d-flex">
            <img v-if="isBase64Image(scope.row.qrCode)" contain :src="scope.row.qrCode" width="150px" height="150px" />
            <img v-else  contain :src="'data:image/jpeg;base64,' + scope.row.qrCode" width="150px" height="150px" />
            <div class="ms-2 d-flex flex-column">
              <span class="qrcode-title">{{ scope.row.title }}</span>
              <el-switch @change="UpdateCode(scope.row)" class="" active-color="green"
                         v-model="scope.row.isActive"></el-switch>
              <div class="d-flex">
                <span class="text-primary mfw-bold">Code URL:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <a class="code-link-a" :href="scope.row.redirectionLink">{{ scope.row.redirectionLink }}</a>
                <el-button @click="CopyLink(scope.row.redirectionLink)" text bg size="small" circle class="ms-2">
                  <i class="bi bi-link-45deg fw-bold"></i>
                </el-button>
              </div>
              <div class="d-flex">
                <span class="text-primary mfw-bold">Tracked URL:&nbsp;</span>
                <a class="code-link-a" :href="scope.row.trackedUrl">{{ scope.row.trackedUrl }}</a>
                <el-button @click="CopyLink(scope.row.trackedUrl)" text bg size="small" circle class="ms-2">
                  <i class="bi bi-link-45deg fw-bold"></i>
                </el-button>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Visits" prop="visits" width="80">
      </el-table-column>

      <el-table-column align="center" fixed="right" label="Action" width="100px">
        <template #default="scope">
          <el-space>
            <el-tooltip content="Download">
              <el-icon @click="downloadCode(scope.row)">
                <i class="mgc_download_line text-primary action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Edit">
              <el-icon @click="editCode(scope.row)">
                <i class="mgc_edit_line text-secondary action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Duplicate">
              <el-icon @click="duplicate(scope.row)">
                <i class="mgc_copy_3_line text-black action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Delete">
              <el-icon color="red" @click="deleteCode(scope.row)">
                <i class="mgc_delete_2_line action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog v-model="deleteDialog" title="Delete Qr Code" width="450">
      <template #default>
        <span>Are you sure? You want to delete <span
            class="mfw-bold text-danger">{{ qrCode.title }}</span> qr code.</span>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="deleteDialog = false">Cancel</el-button>
          <el-button type="danger" @click="deleteDialog = false, RemoveCode()">Delete</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="duplicateDialog" title="Delete Qr Code" width="450">
      <template #default>
        <span>Are you sure? You want to duplicate <span
            class="mfw-bold text-primary">{{ qrCode.title }}</span> qr code.</span>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="duplicateDialog = false">Cancel</el-button>
          <el-button type="primary" @click="duplicateDialog = false, duplicateCode()">Duplicate</el-button>
        </div>
      </template>
    </el-dialog>


    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import qrCodeService from "@/Services/linkService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "QrCodes",
  data() {
    return {
      loading: false,
      dialog: false,
      QrCodes: [],
      qrCode: {
        "title": ""
      },
      deleteDialog: false,
      duplicateDialog: false,
      link: "your link here",
      singleSelect: false,
      user: localStorage.getItem("user"),
      selected: [],
      selectedqrcodeIds: [],
      isActive: true,
      currentCode: [],


      page: 1,
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      pagination: null,
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isActive: true,
        userId: sessionStorage.getItem("userId"),
        linkType: 10,
      },
    }
  },
  methods: {
    isBase64Image(imageUrl) {
      if(imageUrl === '' || imageUrl === null){
        return;
      }
      // Check if the image URL starts with the specified base64 prefix
      return imageUrl.startsWith("data:image/jpeg;base64,") || imageUrl.startsWith("data:image/png;base64,");
    },
    editCode(code) {
      this.$store.state.pageId = code.linkId;
      this.$router.push('/addcode');
    },
    duplicate(code) {
      this.qrCode = {...code};
      this.duplicateDialog = true;
    },
    downloadCode(qrCode) {
      this.qrCode = {...qrCode}
      let a = document.createElement("a"); //Create <a>
        if(!this.isBase64Image(this.qrCode.qrCode)){
      a.href = "data:image/png;base64," + this.qrCode.qrCode; 
      }
      else{
        a.href= this.qrCode.qrCode
      }//Image Base64 Goes here
      a.download = this.qrCode.title +" "+ "Qr Code.png"; //File name Here
      a.click(); //Downloaded file
    },
    CopyLink(link) {
      navigator.clipboard.writeText(link);
    },
    deleteCode(code) {
      this.qrCode = {...code};
      this.deleteDialog = true;
    },
    async UpdateCode(qrCode) {
      try {
        this.loading = true;
        let response = await qrCodeService.addOrUpdate(qrCode)
        this.loader = false;
        if (response.data.isSuccessful) {
          ShowMessage("success", "Qr Code updated");
        }
        else {
          ShowMessage("error", "Qr Code updated failed");
        }
        this.GetAll();
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    async GetAll() {
      try {
        this.loading = true;
        let response = await qrCodeService.getAll(this.filter);
        this.QrCodes = response.data.data ?? [];
        this.pagination = response.data.pagination;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
    async duplicateCode() {
      try {
        this.loading = true;
        let response = await qrCodeService.duplicate(this.qrCode);
        if (response.status === 200) {
          ShowMessage("success", "Qr Code duplicated successfully!");
          this.GetAll();
        }
      } catch (e) {
        this.loading = false;
        ShowMessage("error", "Something went wrong!.")
        console.log(e)
      }
    },
    async RemoveCode() {
      try {
        this.loading = true;
        let response = await qrCodeService.remove(this.qrCode.linkId);
        if (response.status === 200) {
          ShowMessage("success", "QrCode deleted successfully!");
          this.GetAll();
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
        ShowMessage("error", "Something went wrong!.")
      }
    }
  },
  created() {

    this.GetAll();
  }
}
</script>

<style scoped>

</style>