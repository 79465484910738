<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Domains</h3>
      </div>
      <div class="col-12 col-md-4 d-flex">
        <el-input v-model="filter.filterText" @clear="GetAll()" placeholder="search here..." @keyup="GetAll()" clearable class="me-1">
          <template #append>
            <el-button type="primary">
              <i class="bi bi-search"></i>
            </el-button>
          </template>
        </el-input>
        <router-link to="add-domain">
          <el-button @click="$store.state.pageId = 0" type="primary">Add Domain</el-button>
        </router-link>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <el-table :data="Domains" :fit="true" max-height="80vh"
              class="cstm-table" header-cell-class-name="tbl-header" size="small" stripe
    >
      <el-table-column label="#" type="index" width="55"></el-table-column>
      <el-table-column label="Domain" prop="name" min-width="250">
        <template #default="scope">
          <span class="action-icon text-primary mfw-bold"
                @click="takeData(scope.row,'edit')">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Type" prop="type" min-width="150">
        <template #default="scope">
          <span v-if="scope.row.type == 5">Domain</span>
          <span v-if="scope.row.type == 10">Subdomain</span>
        </template>
      </el-table-column>
      <el-table-column label="Landing Url" prop="landingUrl" min-width="150"></el-table-column>
      <el-table-column label="Landing Url" prop="errorUrl" min-width="150"></el-table-column>
      <el-table-column label="Created At" prop="creationDate" min-width="150">
        <template #default="scope">
          <span>{{ getFormattedDate(scope.row.creationDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Status" prop="creationDate" min-width="80" align="center">
        <template #default="scope">
          <div class="bg-danger px-2 rounded-1 text-white status-chip"  v-if="scope.row.status === false">
          <span
          >Failed</span>
          </div>
          <div class="primary-color px-2 rounded-1 text-white status-chip" v-if="scope.row.status === true">
          <span
          >Success</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="Action" width="120px">
        <template #default="scope">
          <el-space size="large">
            <el-tooltip content="Retry">
              <el-icon @click="takeData(scope.row,'retry')">
                <i class="mgc_repeat_line text-secondary action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>

            <el-tooltip content="Delete">
              <el-icon color="red" @click="takeData(scope.row,'del')">
                <i class="mgc_delete_2_line action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog v-model="deleteDialog" title="Delete Domain" width="450">
      <template #default>
        <span>Are you sure? You want to delete <span
            class="mfw-bold text-danger">{{ currentDomain.domainName }}</span> domain.</span>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="deleteDialog = false">Cancel</el-button>
          <el-button type="danger" @click="deleteDialog = false, deleteDomain()">Delete</el-button>
        </div>
      </template>
    </el-dialog>


    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import domainService from "@/Services/domainService";
import moment from "moment";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "DomainsList",
  data() {
    return {
      loading: true,
      deleteDialog: false,
      currentDomain: {},
      Domains: [],
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isActive: true,
        userId: sessionStorage.getItem("userId"),
        linkType: 30,
      },
    }
  },
  methods: {
    takeData(domain, type) {
      this.currentDomain = {...domain};
      if (type === "del") {
        this.deleteDialog = true;
      }
      if (type === 'retry') {
        this.CheckDomain();
      }
      if (type === 'edit') {
        this.$store.state.pageId = this.currentDomain.id;
        this.$router.push('add')
      }

    },
    async deleteDomain() {
      try {
        this.loading = true;
        let response = await domainService.remove(this.currentDomain.id)

        if (response.data == true) {
          ShowMessage("success", "Domain deleted successfully");
          this.GetAll();
        }
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    },
    getFormattedDate(date) {
      return moment(date).format("MMM DD, YYYY");
    },
    async GetAll() {
      try {
        this.loading = true;
        let response = await domainService.getAll(this.filter)
        this.Domains = response.data.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async CheckDomain() {
      try {
        this.loading = true;
        let response = await domainService.add(this.currentDomain.domainName);
        if (response.data.isSuccessful === true) {
          this.loading = false;
          this.currentDomain.status = true;
          this.AddOrUpdate();
          ShowMessage("success", response.data.msg);
        } else {
          this.loading = false;
          ShowMessage("error", response.data.msg);
          this.currentDomain.status = false;
          this.AddOrUpdate();
        }
      } catch (e) {
        this.loading = false;
        ShowMessage("error", "Adding domain failed");
      }
    },
    async AddOrUpdate() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await domainService.addOrUpdate(this.currentDomain)
        this.loading = false;
        this.GetAll();
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
  },
  created() {
    this.GetAll()
  }
}
</script>
<style scoped>
.status-chip {
  width: 80px !important;
  height: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>