import axios from "axios";
import Config from "@/Configuration/Config";

export default {
    allUrl: Config.apiBaseUrl + "api/Pixel/All",
    addOrUpdateUrl: Config.apiBaseUrl + "api/Pixel/AddOrUpdate",
    removeUrl: Config.apiBaseUrl + "api/Pixel/Remove",

    getAll(filter) {
        return axios.post(this.allUrl, filter)
    },
    addOrUpdate(pixel) {
        return axios.post(this.addOrUpdateUrl, pixel)
    },

    remove(id) {
        const fd = new FormData();
        fd.append("pixelId", id);
        return axios({
            method: "post",
            url: this.removeUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
        });
    }
}