<template>
  <div class="container-fluid login-page">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 col-md-5 col-lg-4 justify-content-center align-items-center">
        <el-card shadow="never">
          <div class="text-center">
            <h3 class="fw-bold">Forgot Password?</h3>
            <h6>Just provide us your username</h6>
          </div>
          <el-form label-position="top" class="mt-4">
            <el-form-item>
              <template #label>
                <span class="required">Username / Email</span>
              </template>
              <el-input v-model="username"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <div class="container-fluid px-0">
              <div class="d-flex">
                <div class="col-12 col-md-8 pe-2">
                  <el-button @click="Reset()" :disabled="!username" size="large" type="primary"
                             class="w-100">Reset
                  </el-button>
                </div>
                <div class="col-12 col-md-4">
                  <router-link to="/">
                    <el-button size="large" type="primary" class="w-100">Login</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </el-card>
      </div>
    </div>
    <div v-if="loading" class="spinner-border login-loader"></div>
  </div>
</template>
<script>
import userService from "@/Services/userService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "ForgotPassword",
  data() {
    return {
      loading: false,
      username: "",
    }
  },
  methods: {
    async Reset() {
      try {
        this.loading = true;
        let response = await userService.resetPassword(this.username);
        if (response.data.isSuccessful) {
          this.loading = false;
          ShowMessage("success", "Password sent to your email");
          setTimeout(this.$router.push('/'), 3000)
        } else {
          this.loading = false;
          ShowMessage("error", response.data.msg);
        }
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    }
  }
}
</script>

<style scoped>

</style>