import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css'
import axios from "axios";
import Config from "@/Configuration/Config";


// Appex Charts
import VueApexCharts from "vue3-apexcharts";

store.state.LoggedIn = sessionStorage.getItem("LoggedIn");
axios.defaults.baseURL = Config.apiBaseUrl;
axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("token");

createApp(App).use(store).use(ElementPlus).use(VueApexCharts).use(router).mount('#app')
