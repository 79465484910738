<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Add Pixel</h3>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end">
        <router-link to="pixels">
          <el-button text bg class="me-2">
            <i class="bi bi-arrow-left-short me-2"></i>
            Cancel
          </el-button>
        </router-link>
        <el-button v-if="PixelCode.id < 1" type="primary" @click="AddPixel()" :disabled="!PixelCode.codeName || !PixelCode.type || !PixelCode.code"> Add Pixel</el-button>
        <el-button v-else type="primary" @click="AddPixel()" :disabled="!PixelCode.codeName || !PixelCode.type || !PixelCode.code">Update Pixel</el-button>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="card bg-body-secondary p-3 rounded-1 border-0">
            <el-form :model="PixelCode" label-position="top">
              <el-form-item>
                <template #label>
                  <span class="required">Name</span>
                </template>
                <el-input v-model="PixelCode.codeName"></el-input>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span class="required">Type</span>
                </template>
                <el-select v-model="PixelCode.type">
                  <el-option v-for="(type, index) in CodeTypes" :key="index" :value="type.text">
                    <template #default>
                      <div class="d-flex">
                        <img width="18" :src="type.icon" class="me-2"/>
                        <span>{{ type.text }}</span>
                      </div>
                    </template>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span class="required">Code</span>
                </template>
                <el-input rows="14" v-model="PixelCode.code"
                          type="textarea"
                          placeholder="Insert a entire script or a pixel that will be 'fired' once link is clicked. Example: <script>/*your script</script> or <img src='http://yourimage.png />. Must be a valid HTML code."
                >
                </el-input>
              </el-form-item>
              <span class="text-caption">
                Only apply this pixel if you completely trust its source. Be sure to test things before using it.
              </span>
            </el-form>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="description">
            <span class="description">
              Tracking codes, also known as Retargeting Pixels, are snippets of codes. You can store as many Tracking Codes as you would like in Magiclinkz.com for use in your Magiclinkz.com Tracking Links. Whenever a tracking code is embedded in a link, anyone who clicks on that link will automatically be tagged with a tracking code, and can then be retargeted through the corresponding marketing channel.
            </span>
            <br>
            <span class="description">Do you have questions about how to find your tracking code? Check out our FAQ here:</span>
            <br>
            <a class="caption text-none text-decoration-underline ma-0" href="https:magiclinkz.com/faq" target="_blank">https:magiclinkz.com/faq</a>
            <br>
            <span class="subtitle-2 text--black mt-2">Example:</span>
            <br>
            <el-input rows="22" disabled class="description"
                      type="textarea"
                      placeholder="
                                        <!-- Facebook Pixel Code -->
                                        <script>
                                        !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                        n.callMethod.apply(n,arguments):n.queue.push(arguments)); if(!f._fbq)f. fbq=n;n.push-n;n.loaded=!0;n.version='2.0';
                                        n.callMethod.apply(n,arguments):n.queue.push(arguments)); if(!f._fbq)f. fbq=n;n.push-n;n.loaded=!0;n.version='2.0';
                                        n.queue=[];t-b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s))(window, document, 'script',
                                        https://connect.facebook.net/en_US/fbevents.js');
                                        fbq('init',
                                        fbq('track', 'PageView');
                                        </script>
                                        <noscript><img height='1' width='1' style='display:none' src='https://www.facebook.com/tr? id-1885084354934839&ev=PageView&nonscript=1'/></nonscript>
                                        <!--End Facebook Pixel Code->"
            >
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import pixelCodeService from "@/Services/pixelCodeService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "AddPixel",
  data() {
    return {
      loading: false,
      PixelCode: {
        "id": 0,
        "codeName": "",
        "type": "",
        "code": "",
        "userID": sessionStorage.getItem("userId"),
      },
      CodeTypes: [
        {
          text: "Facebook",
          icon: require("@/assets/PixelsIcons/facebook.svg")
        },
        {
          text: "Google Analytics | Adwords",
          icon: require("@/assets/PixelsIcons/ganalytics.svg")
        }
        ,
        {
          text: "Twitter",
          icon: require("@/assets/PixelsIcons/twitter.svg")
        }
        ,
        {
          text: "LinkedIn",
          icon: require("@/assets/PixelsIcons/linkedIn.svg")
        }
        ,
        {
          text: "Instagram",
          icon: require("@/assets/PixelsIcons/instagram.svg")
        }
        ,
        {
          text: "Pinterest",
          icon: require("@/assets/PixelsIcons/pinterest.svg")
        }
        ,
        {
          text: "Google Tag Manager",
          icon: require("@/assets/PixelsIcons/g-tag-manager.svg")
        }
        ,
        {
          text: "Google Analytics 4 | GA4",
          icon: require("@/assets/PixelsIcons/google.svg")
        }
        ,
        {
          text: "Tiktok Pixels",
          icon: require("@/assets/PixelsIcons/tiktok.svg")
        }

      ],
    }
  },
  methods:{
    async AddPixel() {
      try {
        this.loading = true;
        let response = await pixelCodeService.addOrUpdate(this.PixelCode);
        if (response.status === 200) {
          ShowMessage("success", "Pixel added successfully");
          this.$router.push('/pixels')
        }

      }
      catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    }
  }
}
</script>
<style scoped>

</style>