<template>
  <div class="pa-1 custom-container">
    <!--    <v-img v-if="MyData.length === 0" src="../../../../assets/Images/no-data-svg.svg" width="400"></v-img>-->
    <apexchart   type="bar"  :options="chartOptions" height="280px" :series="MyData"></apexchart>
  </div>
</template>
<script>

export default {
  name: 'PlatformChart',
  data() {
    return {
      TopPlatforms: [],
      Data: [],
      Filter: {
        "days": 30,
        "fromDate": new Date(),
        "toDate": new Date(),
        "userId": localStorage.getItem("userId"),
      },
      chartOptions: {
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
          },
          legend: {
            show: false,
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          legend: {
            show: false
          },
        },
        xaxis: {
          categories: [''],
          show: false,
          labels: {
            show: false
          },
          legend: {
            show: false
          },
          axisTicks: {
            show: false
          }

        },
        //Colors and options
        fill: {
          colors: ["#3E8C3C", "#36b633", "#074d05", "#539352", "#3E8C3C"],
          opacity: 1
        },
      },

      MyData: []
    }
  },
  props: {
    data: {
      type: Array,
      required: false,
    },
  },
  watch: {
    data: {
      handler(newVal) {
        if (newVal) {
          this.GetData();
        }
      },
      deep: true, // Add this line to watch for nested changes in the filter object
    },
  },
  methods: {
    async GetData() {
      try {
        if(this.data.length < 1) return;
        this.TopPlatforms = this.data;
        this.MyData = [];
        this.TopPlatforms.forEach(element => {
          this.MyData.push({name: element.title, data: [element.count]})
        });

      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    this.GetData();
  }

};
</script>
<style scoped>
.custom-container img{
  transform: translateX(40%);
}
.apexcharts-legend{
  display: none !important;
}
</style>
