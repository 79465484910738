<template>
  <div class="h-100 d-flex justify-content-center align-items-center">
    <el-result sub-title="Sorry, permission denied" title="403">
      <template #icon>
        <img alt="403" src="../../assets/svg/pages/403.svg" width="150"/>
      </template>
      <template #extra>
       <router-link to="/home">
         <button  class="btn btn-sm btn-danger">Back To Home</button>
       </router-link>

      </template>
    </el-result>
  </div>
</template>

<script>

export default {
  name: "ForBidden",
  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>