import axios from "axios";
import Config from "@/Configuration/Config";

export default {
    allUrl: Config.apiBaseUrl + "api/Domain/All",
    removeUrl: Config.apiBaseUrl + "api/Domain/Remove",
    addUrl: Config.apiBaseUrl + "api/Domain/AddDomain",
    addOrUpdateUrl: Config.apiBaseUrl + "api/Domain/AddOrUpdate",
    getAllUr: Config.apiBaseUrl+"api/Domain/GetAll",
    all(filter) {
        return axios.post(this.allUrl, filter)
    },
    getAll(filter) {
        return axios.post(this.getAllUr, filter)
    },
    add(domainName){
        console.log(domainName)
        const fd = new FormData();
        fd.append("domain", domainName)
        return  axios({
            method: "post",
            url: this.addUrl,
            data: fd,
            headers: {
                "ContentType": "multipart/form-data",
                "Accept": "application/json"
            }
        })
    },
    addOrUpdate(domain) {
        return axios.post(this.addOrUpdateUrl, domain);
    },
    remove(id) {
        const fd = new FormData();
        fd.append("domainId", id)
        return axios({
            method: "post",
            url: this.removeUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            }
        });
    }
}